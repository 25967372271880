.container-scroller {
  display: flex;
}
.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;
  @media (min-width: 992px){
    width: calc(100% - #{$sidebar-width-lg});
  }

  &.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: 100%;
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 25px 27px;
  padding-bottom: 0;
  width: 100%;
  @include flex-grow(1);
}