.container-scroller-height {
    height: 100%;
}

.container-scroller-inharited-height {
    height: inherit;
}

.theme-bulider-page-height {
    display: flex;
    flex-direction: column;
}

.overflow-auto {
    overflow-y: auto;
}

.custom-paging-header {
    margin: 0.4rem 0 0.3rem 0;
}

/* ============================================================== */
.superadmin-theme-flex {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.superadmin-theme-flex-body-part {
    flex: 1;
}

/* ================================================================ */

.preview-page-division {
    position: relative;
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.preview-desktop-view-height {
    height: 70vh;
    overflow: auto;
}

.preview-mobile-view-height {
    height: 70vh;
    overflow: auto;
}

/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.set-background-image-height {
    min-height: 874px;
    height: 874px;
}


.forscroll #forEnable {
    pointer-events: auto;
}

.forscroll #forDisable {
    pointer-events: none;
}

/* ==================================================================== */

#phone .set-background-image-height {
    min-height: 759px !important;
    height: 776px !important;
    margin: 0 !important;
}

#phone .preview .card .card-body {
    padding: 0.2rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

@media screen and (max-width: 480px) {
    .preview .card .card-body {
        padding: 0.2rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }
}


.set-table-td td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* ========================== safari only ========================= */
@supports (background: -webkit-named-image(i)) {

    .inner-buider-page-height {
        height: unset;
        flex: 1;
        display: flex;
        position: -webkit-sticky;
        margin-bottom: 1px;

    }

    .content-wrapper {
        flex: 1;
        height: 88%
    }

    .survey-left-margin {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .outerheight {
        /* width: unset; */
        margin-right: 45px;
    }

    .custom-grid-margin {
        position: unset;
    }

    #page-content-wrapper {
        display: flex;
        flex-direction: column;
    }

    .mobile-view-height {
        position: unset;
        margin-right: unset;
        margin-bottom: unset;
    }

    .desktop-view-height {
        position: unset;
        margin-right: unset;
        margin-bottom: unset;
    }

    #phone .container-scroller-inharited-height {
        /* height: unset; */
        border-end-end-radius: 7%;
        border-end-start-radius: 7%;
        width: 99.9%;
    }

    #phone .set-background-image-height {
        min-height: 759px !important;
        height: 776px !important;
        margin: 0 !important;
        border-end-end-radius: 7%;
        border-end-start-radius: 7%;
        width: 99.9%;
    }
}

/* ========================== safari only ========================= */

/* ========================== form align ========================== */

.form-align {
    align-items: center;
    overflow-wrap: anywhere;
}

.height-unset {
    height: unset !important;
}

/* ========================== form align ========================== */

/* ========================== scroll dropdown ===================== */
select.form-control,
.select2-container--default select.select2-selection--single,
.select2-container--default .select2-selection--single select.select2-search__field,
select.typeahead,
select.tt-query,
select.tt-hint {
    line-height: 1.45;
    border-color: #d8d6de;
    padding: 0.2rem 2.5rem 0.2rem 0.6rem;
    font-size: 0.82rem;
    color: #6e6b7b;
}

/* ========================== scroll dropdown ===================== */

@media (max-width: 396px) {
    .tableCardmenuBox {
        margin-right: unset;
        grid-column: unset;
    }
}

@media (min-width: 576px) {

    .modal-lg,
    .modal-xl {
        max-width: 80%;
    }
}

.displayLogic-dropdown {
    white-space: normal;
}

.footer-links {
    background: #fff;
    bottom: 0;
    color: #099;
    cursor: pointer;
    margin-bottom: unset;
    margin-top: 1rem;
    padding: 0.5rem;
    position: unset;
    width: 100%;
}

/* ============================ only safari builder page ===================== */
/* @media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
        #wrapper {
            overflow-x: hidden;
            border: 1px solid #d9d9d9;
            border-radius: 0.428rem;
            background: #952727;
        }
    }
} */

/* ============================ only safari builder page ===================== */

/* ============================ sun editor ========================== */

.sun-editor .se-dialog-tabs {
    width: 100%;
    height: 25px;
    border-bottom: 1px solid #e5e5e5;
    display: none;
}

/* ============================ sun editor ========================== */

/* ================================================================== */

.look-and-feel-width {
    width: 100%;
}

.custom-table-layout {
    table-layout: fixed;
    width: 97%
}

.for-zero-bar .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    overflow: hidden;
    color: #000000;
    text-align: unset;
    white-space: nowrap;
    background-color: #eeeeee00 !important;
    transition: unset;
    height: 1rem;
}

/* ================================================================== */

.cc-table td {
    width: 16% !important;
}

.ccc-table td {
    width: 21% !important;
}

.ccc-table th {
    width: 0% !important;
}

/* ==================================================================== */

.checkboxLable .form-check-label {
    margin-left: 5px;
}

/* ==================================================================== */


/* #################### Worked by Manpreet ###############  */


.indent_button {
    padding: 10px 7px;
    display: inline-block;
    background: #5ce5c6;
    border-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 35px;
    margin-right: 7px;
    margin-top: -11px;
    margin-left: -37px;
}


/* ================================================================== */

.wizard-tab-content {
    text-align: unset !important;
}

.ss .form-check-input {
    float: right !important;
    margin-left: 0;
}

/* ================================================================== */




/* ======================== result filter ======================== */

.show-swap-button {
 display: flex;
}

.multiselect-dropdown {
 display: inline-flex;
}

.swapFieldcontaner {
    position: relative;
    display: inline-block;
    width: calc(100% - 100px); /* Adjusting width to account for button */
    padding: 10px;
    box-sizing: border-box;
}

.swap-button {
    position: absolute;
    top: 0;
    right: 0;
    /* width: 60px; */
    height: 100%;
    background-color: #009999;
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateX(0);
    opacity: 0;
}

.show-swap-button:hover .swap-button {
    transform: translateX(20%);
    opacity: 1;
}

/* =============================================================== */

/* =============================================================== */

.chartModelButton {
    display: flex;
    justify-content: space-between;
}

.hListBox {
    height: 300px;
    border: solid 1px #d9d9d9;
    overflow-y: auto;
    padding: 10px;
}

.badge-class {
    background: #009999 !important;
    height: 24px;
}

/* =============================================================== */
